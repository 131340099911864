import './App.css';
import useDocumentTitle from './useDocumentTitle';
import { Link } from 'react-router-dom';
import sculptureData from "./sculpture.json";
import React, { useEffect } from 'react';

interface SculptureProps {
    sculpture: {
        id: number;
        address: {
            EN: string;
            PL: string;
        };
        title: {
            EN: string;
            PL: string;
        };
        info: {
            EN?: string;
            PL?: string;
        };
        description: {
            EN: string[];
            PL: string[];
        };
        images: string[];
        links: {
            url: string;
            name: string;
        }[];
    };
    mosaic: typeof sculptureData.sculpture;
    language: 'EN' | 'PL';
}

function Sculpture({ sculpture, mosaic, language }: SculptureProps) {
    useDocumentTitle(`${sculpture.title[language]} - Wojciech Gniewek`);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [sculpture.id]);

    return (
        <>
            <header><h3>{sculpture.title[language]}</h3></header><main>
            <div className="info">
                {sculpture.info[language]}
            </div>
            <br/>
            <div className="gallery">
                {sculpture.images.map((imgSrc, index) => (
                    <img key={index} src={imgSrc} alt={`${sculpture.title[language]} - ${index + 1}`} />
                ))}
            </div>
            <div className="descriptionContainer">
                {sculpture.description[language].map((desc, index) => (
                    <div key={index} className="text">
                        {desc}
                    </div>
                ))}
            </div>
            <br/>
            <div className="linksContainer">
                {sculpture.links.map((link, index) => (
                    <div key={index} className="link">
                        <a className="refinedLink" href={link.url} target="_blank" rel="noopener noreferrer">
                            <span className="linkText">{link.name}</span>
                            <i className="fas fa-arrow-right linkArrow"></i>
                        </a>
                    </div>
                ))}
            </div></main>
            <aside>
                <br/>
                <br/>
                <div className="mosaic-container">
                    {mosaic.filter(s => s.id !== sculpture.id).map((s, index) => (
                        <div key={index} className="mosaic-item">
                            <Link to={`/${s.address[language]}`}>
                                <div className="image-wrapper">
                                    <img src={s.previewImage} alt={s.title[language]} />
                                    <div className="hover-overlay">
                                        <span>{s.title[language]}</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
                <br/></aside>
        </>
    );
}

export type { SculptureProps };
export { Sculpture };
