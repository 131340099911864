import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import useDocumentTitle from "./useDocumentTitle";
declare module 'emailjs-com';

interface ContactProps {
    language: 'EN' | 'PL';
}

function Contact({ language }: ContactProps) {
    useDocumentTitle(language === 'EN' ? "Contact | Wojciech Gniewek" : "Kontakt | Wojciech Gniewek");
    const form = useRef<HTMLFormElement>(null);
    const successMessage = language === 'EN' ? 'Message sent successfully!' : 'Wiadomość wysłana!';

    const [messageStatus, setMessageStatus] = useState<string>('');
    const [errors, setErrors] = useState<{ user_name?: boolean, user_email?: boolean, message?: boolean }>({});
    const sendEmail = (e: React.FormEvent) => {
        e.preventDefault();
        const elements = form.current!.elements as any;
        const user_name = elements.user_name as HTMLInputElement;
        const user_email = elements.user_email as HTMLInputElement;
        const message = elements.message as HTMLTextAreaElement;
        const hasErrors: any = {};
        if (!user_name.value) {
            hasErrors.user_name = true;
        }
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!user_email.value || !emailPattern.test(user_email.value)) {
            hasErrors.user_email = true;
        }
        if (!message.value) {
            hasErrors.message = true;
        }
        setErrors(hasErrors);
        if (Object.keys(hasErrors).length) {
            return;
        }
        emailjs.sendForm('service_vxvg8h6', 'template_pyp3gct', form.current!, 'wqK6eTxvGUuL4Yyfq')
            .then((result: any) => {
                console.log(result.text);
                setMessageStatus(successMessage);
                if (form.current) {
                    form.current.reset();
                }
            }, (error: any) => {
                console.log(error.text);
                setMessageStatus('Failed to send message.');
            });
    };

    return (
        <div>
            <br/>
            <main>
                <div className="info">
                    wojciech@gniewek.art
                </div>
                <form ref={form} onSubmit={sendEmail} className="contact-form" noValidate>
                    <input
                        type="text"
                        name="user_name"
                        placeholder={language === 'EN' ? "Name" : "Imię"}
                        className={errors.user_name ? 'error' : ''}
                    />
                    <input
                        type="email"
                        name="user_email"
                        placeholder="Email"
                        className={errors.user_email ? 'error' : ''}
                    />
                    <textarea
                        name="message"
                        placeholder={language === 'EN' ? "Message" : "Wiadomość"}
                        className={errors.message ? 'error' : ''}
                    ></textarea>
                    {messageStatus !== (language === 'EN' ? 'Message sent successfully!' : 'Wiadomość wysłana!') && <input type="submit" value={language === 'EN' ? "Send" : "Wyślij"} />}
                    {messageStatus && <p className={messageStatus === (language === 'EN' ? 'Message sent successfully!' : 'Wiadomość wysłana!') ? 'success-message' : ''}>{messageStatus}</p>}
                </form>
            </main>
        </div>
    );
}

export default Contact;
