import React from 'react';
import './App.css';
import useDocumentTitle from './useDocumentTitle';

interface InfoProps {
    language: 'EN' | 'PL';
}

function Info({ language }: InfoProps) {
    useDocumentTitle(language === 'EN' ? "Funding | Wojciech Gniewek" : "Dofinansowanie | Wojciech Gniewek");

    return (
        <div>
            <main>
            {/*
                <div className="text">
                    {language === 'EN'
                        ? 'Operation within sub-action 19.2 of the Local Action Group "Ziemia Biłgorajska" named "Biłgoraj Sculpture Initiative", whose goal is to establish a business activity consisting in making sculptures, was supported by the European Agricultural Fund for Rural Development: Europe investing in rural areas.'
                        : 'Operacja w ramach poddziałania 19.2 Lokalnej Grupy Działania "Ziemia Biłgorajska" o nazwie "Biłgorajskie Przedsięwzięcie Rzeźbiarskie", której celem jest założenie działalności gospodarczej polegającej na wykonywaniu rzeźb, została wsparta przez Europejski Fundusz Rolny na rzecz Rozwoju Obszarów Wiejskich: Europa inwestująca w obszary wiejskie.'}
                </div>
                */}
            
            
            
            <div className="gallery">
                <img src="/info.webp" alt="Info" />
            </div>
                </main>
            
            {/*
            <div className="logos">
                <div className="logogallery"><img src="/eu.webp" alt="Unia Europejska" /></div>
                <div className="logoinfo">{language === 'EN' ? 'European Union' : 'Unia Europejska'}</div>
                <br/>
                <div className="logogallery"><img src="/prow.jpg" alt="Program Rozwoju Obszarów Wiejskich" /></div>
                <div className="logoinfo">{language === 'EN' ? 'Rural Development Programme' : 'Program Rozwoju Obszarów Wiejskich'}</div>
                <br/>
                <div className="logogallery"><img src="/lgd-logo.svg" alt="Lokalna Grupa Działania - Ziemia Biłgorajska" /></div>
                <div className="logoinfo">{language === 'EN' ? 'Local Action Group - "Ziemia Biłgorajska"' : 'Lokalna Grupa Działania - "Ziemia Biłgorajska"'}</div>
            </div>
            */}

        </div>
    );
}
export default Info;
