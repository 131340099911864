import React from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import sculptureData from './sculpture.json';

interface SculpturesProps {
    language: 'EN' | 'PL';
}

function Sculptures({ language }: SculpturesProps) {
    return (
        <>
            <br/><main>
            <div className="mosaic-container">
                {sculptureData.sculpture.map((sculpture, index) => (
                    <div key={index} className="mosaic-item">
                        <Link to={`/${sculpture.address[language]}`}>
                            <div className="image-wrapper">
                                <img src={sculpture.previewImage} alt={sculpture.title[language]} />
                                <div className="hover-overlay">
                                    <span>{sculpture.title[language]}</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div></main>
        </>
    );
}

export default Sculptures;
