import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import arkData from './ark.json';
import useDocumentTitle from './useDocumentTitle';

interface ArkProps {
    ark: {
        id: number;
        address: {
            EN: string;
            PL: string;
        };
        title: {
            EN: string;
            PL: string;
        };
        info: {
            EN: string;
            PL: string;
        };
        images: string[];
        description: {
            EN: string[];
            PL: string[];
        };
        links: {
            EN: {
                url: string;
                name: string;
            }[];
            PL: {
                url: string;
                name: string;
            }[];
        };
        buttonImage: string;
        button: {
            EN: string;
            PL: string;
        };
        buttonName: {
            EN: string;
            PL: string;
        };
    };
    arks: typeof arkData.ark;
    language: 'EN' | 'PL';
}

function Ark({ ark, arks, language }: ArkProps) {
    const navigate = useNavigate();
    useDocumentTitle(language === 'PL' ? `Arka - Wojciech Gniewek` : `Ark - Wojciech Gniewek`);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [ark.id]);

    return (
        <>
            <header><h3>{ark.title[language]}</h3></header><main>
            <div className="to" style={{ marginBottom: '40px' }}>
                <div className="info">{ark.info[language]}</div>
            </div>
            <div className="gallery">
                {ark.images.map((imgSrc, index) => (
                    <>
                        <img key={index} src={imgSrc} alt={`Ark - ${index + 1}`} />
                        {ark.id === 3 && index === 0 && (
                            <div className="textlabel" style={{ marginTop: '-20px', marginBottom: '30px'}}>
                                {language === 'PL' ? 'Wojciech Gniewek (autor) i Petur Magnusson' : 'Wojciech Gniewek (author) and Petur Magnusson'}
                            </div>
                        )}
                    </>
                ))}
            </div>
            <div className="descriptionContainer">
                {ark.description[language].length > 0 && (
                    <>
                        {ark.description[language].map((desc, index) => (
                            <div key={index} className="text">
                                {desc}
                            </div>
                        ))}
                        <br />
                    </>
                )}
            </div>

            <div className="linksContainer">
                {ark.links[language].length > 0 && (
                    <>
                        {ark.links[language].map((link, index) => (
                            <div key={index} className="link">
                                <a className="refinedLink" href={link.url} target="_blank" rel="noopener noreferrer">
                                    <span className="linkText">{link.name}</span>
                                    <i className="fas fa-arrow-right linkArrow"></i>
                                </a>
                            </div>
                        ))}
                        <br />
                    </>
                )}
            </div>
            <div className="nextButton3">
                <div
                    onClick={() => navigate(`/${ark.button[language]}`)}
                    className="image-wrapper3"
                >
                    <img className="gallery3" src={ark.buttonImage} alt={`Next page - ${ark.buttonName[language]}`} />
                    <div className="hover-overlay3"></div>
                    <div className="hover-text">
                        <span>{ark.id === 5 ? <><i className="fas fa-arrow-left linkArrow3"></i>&#160;</> : ''}{ark.buttonName[language]}</span>
                        {ark.id !== 5 && <><span>&#160;<i className="fas fa-arrow-right linkArrow2"></i></span></>}
                    </div>
                </div>
            </div>



        </main>
        </>
    );
}

export type { ArkProps };
export { Ark };
