import { useLocation, BrowserRouter as Router, Route, Link, Routes, useNavigate, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import './App.css';
import { Sculpture, SculptureProps } from './Sculpture';
import sculptureData from './sculpture.json';
import Sculptures from './Sculptures';
import Contact from './Contact';
import arkData from './ark.json';
import { Ark, ArkProps } from './Ark';
import Info from "./Info";
import PrivacyPolicy from "./PrivacyPolicy";

const generateLanguageMap = () => {
    let languageMap: { [key: string]: string } = {};

    languageMap = {
        '/sculpture': '/rzezba',
        '/about': '/omnie',
        '/contact': '/kontakt',
        '/funding': '/dofinansowanie',
        '/ark': '/arka',
        '/ark/2': '/arka/2',
        '/ark/3': '/arka/3',
        '/ark/4': '/arka/4',
        '/ark/5': '/arka/5',
        '/privacy-policy': '/polityka-prywatnosci'
    };

    sculptureData.sculpture.forEach((sculptureItem: SculptureProps['sculpture']) => {
        languageMap[`/${sculptureItem.address.EN}`] = `/${sculptureItem.address.PL}`;
    });

    return languageMap;
};

const languageMap = generateLanguageMap();

function App() {
    const [language, setLanguage] = useState<'EN' | 'PL'>('PL');
    const toggleLanguage = () => setLanguage(language === 'EN' ? 'PL' : 'EN');

    return (
        <Router>
            <header><h1><Link to="/" className="header-link">WOJCIECH GNIEWEK</Link></h1></header>
            <NavigationBar language={language} toggleLanguage={toggleLanguage} languageMap={languageMap} />
            <br />
            <div className="main-container">
                <div className="centered page">
                    <div className="content-box">
                        <Routes>
                            <Route path="/" element={<Navigate to="/arka" replace />} />
                            <Route path="/sculpture" element={<Sculptures language={language} />} />
                            <Route path="/rzezba" element={<Sculptures language={language} />} />
                            <Route path="/contact" element={<Contact language='EN' />} />
                            <Route path="/kontakt" element={<Contact language='PL' />} />
                            <Route path="/funding" element={<Info language='EN' />} />
                            <Route path="/dofinansowanie" element={<Info language='PL' />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy language='EN' />} />
                            <Route path="/polityka-prywatnosci" element={<PrivacyPolicy language='PL' />} />
                            {/*<Route path="/about" element={<About language='EN' />} />
                            <Route path="/omnie" element={<About language='PL' />} />*/}
                            {arkData.ark.map((arkItem: ArkProps['ark']) => (
                                <>
                                    <Route key={arkItem.id} path={`/${arkItem.address.EN}`} element={<Ark ark={arkItem} arks={arkData.ark} language='EN' />} />
                                    <Route key={arkItem.id} path={`/${arkItem.address.PL}`} element={<Ark ark={arkItem} arks={arkData.ark} language='PL' />} />
                                </>
                            ))}
                            {sculptureData.sculpture.map((sculptureItem: SculptureProps['sculpture']) => (
                                <Route key={sculptureItem.id} path={`/${sculptureItem.address[language]}`} element={<Sculpture sculpture={sculptureItem} mosaic={sculptureData.sculpture} language={language} />} />
                            ))}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
            <br />

            <footer>
                <p className="footer-small-screen">
                    &copy; Wojciech Gniewek 2024 |  
                    <Link to={language === 'EN' ? "/privacy-policy" : "/polityka-prywatnosci"} style={{ textDecoration: 'none', color: 'gray' }}>
                        {language === 'EN' ? ' Privacy Policy ' : ' Polityka prywatności '}
                    </Link>
                     | <a href="https://kgniewek.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'gray', textDecoration: 'none' }}>
                        Website by kgniewek.com
                    </a>
                </p>

                <p className="footer-large-screen">
                    &copy; Wojciech Gniewek 2024 &nbsp; | &nbsp; 
                    <Link to={language === 'EN' ? "/privacy-policy" : "/polityka-prywatnosci"} style={{ textDecoration: 'none', color: 'gray' }}>
                        {language === 'EN' ? ' Privacy Policy ' : ' Polityka prywatności '}
                    </Link>
                    &nbsp; | &nbsp;
                    <a href="https://kgniewek.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'gray', textDecoration: 'none' }}>
                        Website by kgniewek.com
                    </a>
                </p>
            </footer>
        </Router>
    );
}

function NavigationBar({ language, toggleLanguage, languageMap }: { language: 'EN' | 'PL'; toggleLanguage: () => void; languageMap: { [key: string]: string }; }) {
    const location = useLocation();
    const navigate = useNavigate();
    const changeLanguage = () => {
        toggleLanguage();
        let newPath = location.pathname;

        if (language === 'EN') {
            newPath = languageMap[newPath] || newPath;
        } else {
            newPath = Object.keys(languageMap).find(key => languageMap[key] === newPath) || newPath;
        }
        navigate(newPath);
    };
    return (
        <nav>
            <Link to={language === 'EN' ? "/ark" : "/arka"} className={`nav-buttona ${location.pathname === "/ark" || location.pathname === "/arka" ? "active" : ""}`}>{language === 'EN' ? 'ARK' : 'ARKA'}
                <span className="nav-link-line"></span>
                <span className="nav-link-active-line"></span>
            </Link>
            <Link to={language === 'EN' ? "/sculpture" : "/rzezba"} className={`nav-buttona ${location.pathname === "/sculpture" || location.pathname === "/rzezba" ? "active" : ""}`}>{language === 'EN' ? 'SCULPTURE' : 'RZEŹBA'}
                <span className="nav-link-line"></span>
                <span className="nav-link-active-line"></span>
            </Link>
            {/* <Link to={language === 'EN' ? "/about" : "/omnie"} className={`nav-buttona ${location.pathname === "/about" || location.pathname === "/omnie" ? "active" : ""}`}>{language === 'EN' ? 'ABOUT' : 'O MNIE'}
                    <span className="nav-link-line"></span>
                    <span className="nav-link-active-line"></span>
                </Link> */}
            <Link to={language === 'EN' ? "/contact" : "/kontakt"} className={`nav-buttona ${location.pathname === "/contact" || location.pathname === "/kontakt" ? "active" : ""}`}>{language === 'EN' ? 'CONTACT' : 'KONTAKT'}
                <span className="nav-link-line"></span>
                <span className="nav-link-active-line"></span>
            </Link>
            <Link to={language === 'EN' ? "/funding" : "/dofinansowanie"} className={`nav-buttona ${location.pathname === "/funding" || location.pathname === "/dofinansowanie" ? "active" : ""}`}>{language === 'EN' ? 'FUNDING' : 'DOFINANSOWANIE'}
                <span className="nav-link-line"></span>
                <span className="nav-link-active-line"></span>
            </Link>
            <button className={`nav-buttona ${language}`} onClick={changeLanguage}>
                {language === 'EN' ? 'PL' : 'EN'}
                <span className="nav-link-line"></span>
                <span className="nav-link-active-lineicon"></span>
            </button>
            <a href="https://www.instagram.com/gniewek.wojciech/" target="_blank" rel="noopener noreferrer" className="nav-buttonb">
                <i className="fab fa-instagram icon-link-nav"></i>
                <span className="nav-link-line"></span>
                <span className="nav-link-active-lineicon"></span>
            </a>
        </nav>
    );
}

function NotFound() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/");
    }, [navigate]);
    return <div> </div>;
}

export default App;
