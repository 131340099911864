import React, { useEffect } from 'react';
import useDocumentTitle from './useDocumentTitle';

interface PrivacyPolicyProps {
    language: 'EN' | 'PL';
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = ({ language }) => {
    useDocumentTitle(language === 'EN' ? 'Privacy Policy | Wojciech Gniewek' : 'Polityka Prywatności | Wojciech Gniewek');

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page immediately
    }, []);

    return (
        <div className="content-box">
            <h2>{language === 'EN' ? 'Privacy Policy' : 'Polityka Prywatności'}</h2>
            <p>
                {language === 'EN' ? (
                    <>
                        <strong>What data do we collect?</strong><br />
                        We only collect the data you provide in the contact form: your name, email address, message, and the time when the message is sent.<br /><br />

                        <strong>Why do we collect this data?</strong><br />
                        This data is collected solely for the purpose of responding to your inquiries. It allows us to contact you in response to your message and helps us understand the context of your inquiry.<br /><br />

                        <strong>Who receives your data?</strong><br />
                        The data you submit is sent directly to Wojciech Gniewek’s email address. No one else has access to this information. It is not shared with any third parties.<br /><br />

                        <strong>What about cookies or tracking?</strong><br />
                        This website does not use cookies or any form of tracking. The only information we receive is what you choose to submit through the contact form.<br /><br />

                        <strong>How is your data used?</strong><br />
                        The information you provide is used only to reply to your message. It is not used for marketing, newsletters, or any other purpose.<br /><br />

                        <strong>How long do we keep your data?</strong><br />
                        Your data is retained only as long as necessary to respond to your inquiry and for the context of the ongoing communication. Afterward, it will not be stored unless required by law.<br /><br />

                        <strong>Your rights</strong><br />
                        You have the right to request access to the data you have submitted, to correct any inaccuracies, or to request that it be deleted. To exercise these rights, please contact us at wojciech@gniewek.art.<br />
                    </>
                ) : (
                    <>
                        <strong>Jakie dane zbieramy?</strong><br />
                        Zbieramy tylko dane, które podajesz w formularzu kontaktowym: imię, adres e-mail, wiadomość oraz czas wysłania wiadomości.<br /><br />

                        <strong>Dlaczego zbieramy te dane?</strong><br />
                        Dane te są zbierane wyłącznie w celu udzielenia odpowiedzi na Twoje zapytania. Pozwalają nam skontaktować się z Tobą w odpowiedzi na wiadomość i pomagają zrozumieć kontekst Twojego zapytania.<br /><br />

                        <strong>Kto otrzymuje Twoje dane?</strong><br />
                        Dane, które przesyłasz, są wysyłane bezpośrednio na adres e-mail Wojciecha Gniewka. Nikt inny nie ma dostępu do tych informacji. Dane nie są udostępniane żadnym stronom trzecim.<br /><br />

                        <strong>Co z cookies lub śledzeniem?</strong><br />
                        Ta strona internetowa nie używa plików cookies ani żadnej formy śledzenia. Jedyną informacją, jaką otrzymujemy, jest to, co zdecydujesz się podać w formularzu kontaktowym.<br /><br />

                        <strong>Jak wykorzystywane są Twoje dane?</strong><br />
                        Informacje, które podajesz, są wykorzystywane wyłącznie do odpowiedzi na Twoją wiadomość. Nie są wykorzystywane do marketingu, newsletterów ani innych celów.<br /><br />

                        <strong>Jak długo przechowujemy Twoje dane?</strong><br />
                        Twoje dane są przechowywane tylko tak długo, jak jest to konieczne do udzielenia odpowiedzi na Twoje zapytanie i dla kontekstu trwającej komunikacji. Po tym okresie nie będą przechowywane, chyba że wymagają tego przepisy prawa.<br /><br />

                        <strong>Twoje prawa</strong><br />
                        Masz prawo do żądania dostępu do danych, które przekazałeś/aś, do poprawienia wszelkich nieścisłości lub do żądania ich usunięcia. Aby skorzystać z tych praw, prosimy o kontakt na adres wojciech@gniewek.art.<br />
                    </>
                )}
            </p>
        </div>
    );
};

export default PrivacyPolicy;
